html,
body,
#root {
  height: 100%;
}

body {
  /* font-family: 'Arial'; */
  font-family: Arial, sans-serif !important;
  background-color: #fff;
}

.image-container img {
  width: 100%;
}

.locker-logo {
  font-weight: 100;
  display: flex;
  justify-content: center;
}

.main-heading h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  font-weight: 1000;
  color: #012267;
}

.locker-number {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 3rem;
  right: 1.5rem;
  font-size: 1rem;
  font-weight: 1000;
  color: #012267;
  background-color: #bbb;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  height: 2rem;
  width: 2rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 1rem;
  /* Using % for relative positioning */
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center horizontally */
  transform: translateX(-50%);
}

.dot1 {
  height: 1.5rem;
  width: 1.5rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Using % for relative positioning */
  left: 60%;
  /* Center horizontally */
  transform: translateX(-50%);
}

.dot2 {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 8.5rem;
  /* Using % for relative positioning */
  left: 60%;
  /* Center horizontally */
  transform: translateX(-50%);
}

.dot3 {
  height: 3rem;
  width: 3rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 5rem;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
}

.lockers {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.lockers img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.locker-timer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 2px;
}

.locker-timer p {
  margin: 0;
  padding: 0;
  color: #012267;
  font-weight: 600;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 97vh;
}

.form-container {
  width: 30%;
  margin-top: 50px;
}

.form-container input {
  background-color: #fff;
  padding: 10px;
}

.btn.btn-prim {
  padding: 10px 40px;
  width: 100%;
  line-height: 19px;
  font-size: 13px;
  font-family: Arial;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}

.btn.btn-prim:hover,
.btn.btn-prim:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}

.btn.btn-prim.btn-transparent {
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}

.btn.btn-prim.btn-transparent:hover {
  border: 1px solid rgba(1, 34, 105, 1);
  background: rgba(1, 34, 105, 1);
}

.lockers-dashboard {
  margin-top: 3rem;
  position: relative;
}

.time-calc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  width: 400px;
  margin-top: 30px;
  padding: 10px;
  border-radius: 10px;
}

.time-calc p {
  margin: 0;
}

.modal-content {
  /* width: 30%;
  height: 50%; */
  width: auto;
  height: auto;
  margin: auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  border-radius: 10px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.modal-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 50px;
}

.modal-btn1 {
  background-color: #228b22;
  color: #eeeeee;
  padding: 5px 15px;
  border: 0.5px solid #5aee47;
  border-radius: 10px;
}

.modal-btn2 {
  background-color: #a40000;
  color: #eeeeee;
  padding: 5px 15px;
  border: 0.5px solid #f1522e;
  border-radius: 10px;
}

/* sidebar */
.col.col-sidebar {
  background: #fff;
  height: 100%;
  max-width: 100%;
  width: 300px;
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  /* left: 0; */
  overflow-x: hidden;
  overflow-y: auto;
}

/* width */
.col.col-sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.col.col-sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.col.col-sidebar::-webkit-scrollbar-thumb {
  background: rgba(1, 34, 105, 1);
  border-radius: 10px;
}

/* Handle on hover */
.col.col-sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 34, 105, 0.7);
}

.sidebar_wrap {
  padding: 30px 30px;
}

.logo-sidebar-wrap {
  margin-bottom: 35px;
}

section.sidebar_wrap ul li {
  padding: 13px 20px;
}

section.sidebar_wrap ul li a {
  color: #999999;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
  text-decoration: none;
  transition: 0.3s;
}

section.sidebar_wrap ul li a.active,
section.sidebar_wrap ul li a:hover {
  color: #012269;
  font-size: 12px;
  font-family: Arial;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
}

section.sidebar_wrap ul li img {
  width: 18px;
  object-fit: contain;
  margin-right: 20px;
  filter: brightness(0);
  opacity: 0.3;
  transition: 0.3s;
}

section.sidebar_wrap ul li .active img,
section.sidebar_wrap ul li a:hover img {
  filter: brightness(1);
  opacity: 1;
}

.col.col-dashboard {
  height: 100%;
  max-width: 100%;
  width: calc(100% - 300px);
  flex: 0 0 auto;
  padding: 0 40px;
  margin-left: 300px;
}

.general-dashboard table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.dashboard-top-row {
  padding-bottom: 50px;
}

.user-wrap h3 {
  color: black;
  font-size: 20px;
  font-family: Arial;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
  margin-bottom: 0;
}

.user-wrap p {
  color: #999999;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}

.filter-right-sec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter-box,
.search-box {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  padding: 5px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.general-dashboard table th {
  border-bottom: none;
  color: #222222;
  font-size: 10px;
  font-family: Arial;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
  padding: 10px 20px;
}

.general-dashboard table tr {
  border: 0.5px #eeeeee solid;
}

.general-dashboard table td {
  background: #fff;
  border-bottom: none;
  padding: 10px 20px;
  color: #222222;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  text-transform: capitalize;
}

span.status.success {
  background: rgba(25, 182, 0, 0.07);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 12px;
  color: #19b600;
  font-size: 10px;
  font-family: Arial;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

span.status.danger {
  background: rgba(182, 0, 0, 0.07);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 12px;
  color: #b60000;
  font-size: 10px;
  font-family: Arial;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

span.status.warning {
  background: rgba(182, 120, 0, 0.07);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 12px;
  color: rgba(182, 120, 0, 1);
  font-size: 10px;
  font-family: Arial;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

.usr_det_tb {
  display: flex;
  align-items: center;
}

.usr_det_tb .img_wrap_user_tb {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #eeeeee;
  margin-right: 30px;
}

.usr_det_tb .img_wrap_user_tb img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 20px;
}

.table-num {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.half-locker {
  margin-top: 30px;
}

.dig-num {
  display: flex;
  align-items: center;
}

.dig-num a {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #999999;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
  margin-right: 5px;
}

.dig-num span,
.dig-num .current_pg,
.dig-num .pg_links.active,
.dig-num a.active {
  background: rgba(1, 34, 105, 0.1);
  border-radius: 12px;
  border: 0.25px #012269 solid;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #012269;
}

.header2 {
  padding: 10px;
}

.main-heading2 h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  font-weight: 800;
  color: #012267;
}

.name-wrap h5 {
  color: black;
  font-size: 15px;
  font-family: Arial;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

.name-wrap p {
  color: #777777;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}

.wel-not h1 {
  color: #012269;
  font-size: 22px;
  font-family: Arial;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
}

.prof-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.prof-sett {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.det-prof {
  margin-right: 15px;
}

.locker-button-text {
  position: absolute;
  top: 45%;
  left: 82%;
  /* Center horizontally */
  transform: translateX(-50%);
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
}

.locker-button-text-VA {
  position: absolute;
  top: 45%;
  left: 100%;
  /* Center horizontally */
  transform: translateX(-50%);
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
}

.modal-caller {
  display: none;
}

@media (min-width: 1800px) and (max-width: 2600px) {
  .dot {
    height: 25px;
    width: 25px;
    top: 33px;
    /* Using % for relative positioning */
    left: 50%;
    /* Center horizontally */
  }

  .dot1 {
    top: 0.8rem;
    /* Using % for relative positioning */
    left: 60%;
    /* Center horizontally */
  }

  .dot2 {
    top: 10.5rem;
    /* Using % for relative positioning */
    left: 60%;
    /* Center horizontally */
  }

  .dot3 {
    top: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Using % for relative positioning */
    left: 50%;
    /* Center horizontally */
  }
}

@media (max-width: 1080px) {
  .mob-logo {
    display: block;
  }

  header .col-lg-6 {
    order: 2;
  }

  header .col-lg-6.text-end {
    order: 1;
  }

  .wel-not {
    padding: 75px 0 0;
  }

  .det-prof {
    display: none;
  }

  .mob_btn_hambrgr {
    position: unset;
    margin: 0;
    width: 98px;
  }

  .mob_btn_hambrgr img {
    width: auto;
  }

  .logo-wrap.mob-logo img {
    width: 75px;
  }

  .all-head {
    display: flex;
  }

  section.sidebar_wrap .logo-wrap {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: -1;
  }

  .inner-det-wrap .col.col-name {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-email {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-contact {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-date {
    width: 100%;
    padding: 0 30px !important;
  }

  .scan-img-appnt {
    text-align: center;
  }

  .conditions-appnt .appnt-alert {
    margin-left: 0;
    margin-top: 15px;
    padding: 7px 30px;
    font-size: 10px;
  }

  .inner-det-wrap {
    padding-right: 0;
  }

  .row-top-appnt {
    margin-bottom: 25px;
  }

  .form-box.card-box.appnt-set-box .details-wrap-appnt h3 {
    font-size: 15px;
    padding: 10px 0;
    margin-bottom: 30px;
  }

  .form-box.card-box .details-wrap-appnt h4 {
    margin-bottom: 20px;
  }

  .inner-det-wrap .col {
    width: 100%;
  }

  .btns-wrap.appnt .btn {
    margin: 5px 0 !important;
  }

  .btns-wrap.appnt {
    text-align: center;
  }

  .scan-img-appnt {
    margin-top: 20px;
  }

  .conditions-appnt .d-flex.align-items-center.justify-content-end {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .user-wrap h3 {
    font-size: 20px;
  }

  .form-box.card-box.appnt-set-box h3 {
    color: #222222;
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 6px;
  }

  .form-box.card-box.appnt-set-box p,
  .user-wrap p {
    font-size: 14px;
  }

  .col.col-sidebar {
    transition: 0.5s linear;
  }

  .col.col-sidebar.sidebar.active {
    left: 0;
  }

  .mob_btn_hambrgr {
    display: flex;
  }

  .cont-detail.d-flex {
    flex-wrap: wrap;
  }

  .cont-detail a {
    width: 100%;
  }

  .media-opt-wrap {
    margin-bottom: 15px;
  }

  .media-selection h2 {
    margin-bottom: 25px;
  }

  .col-lg-3.col-cust-3,
  .col-lg-9.col-cust-9 {
    width: 100%;
  }

  .media-footer {
    position: unset;
    width: 100%;
    padding-bottom: 35px;
  }

  .scanner {
    text-align: center;
    margin: 0 0 20px;
  }

  .scan-box-wrap {
    padding: 30px 60px 30px 40px;
  }

  .label-wrap {
    margin-bottom: 15px;
  }

  .upld-img-wrap {
    height: 250px;
  }

  .modal .main-img {
    height: 500px !important;
    margin-top: 30px;
  }

  .modal .label-wrap {
    margin-bottom: 20px;
  }

  .mob-1 {
    order: 1;
  }

  .mob-2 {
    order: 2;
  }

  .cont_form_wrap {
    padding: 30px;
  }

  .form-wrap input,
  .form-wrap select {
    margin-bottom: 10px;
  }

  .col.col-sidebar {
    width: 250px;
    position: fixed;
    left: -100%;
    z-index: 9;
  }

  .col.col-dashboard {
    width: 100%;
    padding: 0 20px;
    margin-left: 0;
  }

  .wel-not h1 {
    text-align: center;
    font-size: 16px;
  }

  .prof-sett {
    justify-content: center;
  }

  .lockers img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .dot {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .dot1 {
    top: 0.6rem;
    left: 60%;
    transform: translateX(-50%);
  }

  .half-locker {
    margin-top: 18px;
  }

  .dot2 {
    top: 6rem;
    left: 60%;
    transform: translateX(-50%);
  }

  .dot3 {
    top: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 991px) {
  .header-row {
    flex-direction: row;
  }

  .mob-logo {
    display: block;
  }

  header .col-lg-6 {
    order: 2;
  }

  header .col-lg-6.text-end {
    order: 1;
  }

  .wel-not {
    padding: 75px 0 0;
  }

  .det-prof {
    display: none;
  }

  .mob_btn_hambrgr {
    position: unset;
    margin: 0;
    width: 98px;
  }

  .mob_btn_hambrgr img {
    width: auto;
  }

  .logo-wrap.mob-logo img {
    width: 75px;
  }

  .all-head {
    display: flex;
  }

  section.sidebar_wrap .logo-wrap {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: -1;
  }

  .inner-det-wrap .col.col-name {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-email {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-contact {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-date {
    width: 100%;
    padding: 0 30px !important;
  }

  .scan-img-appnt {
    text-align: center;
  }

  .conditions-appnt .appnt-alert {
    margin-left: 0;
    margin-top: 15px;
    padding: 7px 30px;
    font-size: 10px;
  }

  .inner-det-wrap {
    padding-right: 0;
  }

  .row-top-appnt {
    margin-bottom: 25px;
  }

  .form-box.card-box.appnt-set-box .details-wrap-appnt h3 {
    font-size: 15px;
    padding: 10px 0;
    margin-bottom: 30px;
  }

  .form-box.card-box .details-wrap-appnt h4 {
    margin-bottom: 20px;
  }

  .inner-det-wrap .col {
    width: 100%;
  }

  .btns-wrap.appnt .btn {
    margin: 5px 0 !important;
  }

  .btns-wrap.appnt {
    text-align: center;
  }

  .scan-img-appnt {
    margin-top: 20px;
  }

  .conditions-appnt .d-flex.align-items-center.justify-content-end {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .user-wrap h3 {
    font-size: 20px;
  }

  .form-box.card-box.appnt-set-box h3 {
    color: #222222;
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 6px;
  }

  .form-box.card-box.appnt-set-box p,
  .user-wrap p {
    font-size: 15px;
  }

  .col.col-sidebar {
    transition: 0.5s linear;
  }

  .col.col-sidebar.sidebar.active {
    left: 0;
  }

  .mob_btn_hambrgr {
    display: flex;
  }

  .cont-detail.d-flex {
    flex-wrap: wrap;
  }

  .cont-detail a {
    width: 100%;
  }

  .media-opt-wrap {
    margin-bottom: 15px;
  }

  .media-selection h2 {
    margin-bottom: 25px;
  }

  .col-lg-3.col-cust-3,
  .col-lg-9.col-cust-9 {
    width: 100%;
  }

  .media-footer {
    position: unset;
    width: 100%;
    padding-bottom: 35px;
  }

  .scanner {
    text-align: center;
    margin: 0 0 20px;
  }

  .scan-box-wrap {
    padding: 30px 60px 30px 40px;
  }

  .label-wrap {
    margin-bottom: 15px;
  }

  .upld-img-wrap {
    height: 250px;
  }

  .modal .main-img {
    height: 500px !important;
    margin-top: 30px;
  }

  .modal .label-wrap {
    margin-bottom: 20px;
  }

  .mob-1 {
    order: 1;
  }

  .mob-2 {
    order: 2;
  }

  .cont_form_wrap {
    padding: 30px;
  }

  .form-wrap input,
  .form-wrap select {
    margin-bottom: 10px;
  }

  .col.col-sidebar {
    width: 250px;
    position: fixed;
    left: -100%;
    z-index: 9;
  }

  .col.col-dashboard {
    width: 100%;
    padding: 0 20px;
    margin-left: 0;
  }

  .wel-not h1 {
    text-align: center;
    font-size: 16px;
  }

  .prof-sett {
    justify-content: center;
  }

  /* .modal-content {
    width: 60%;
    height: 30%;
  } */

  .locker-logo {
    text-align: center;
    width: 25%;
    margin-left: 38%;
  }

  .container-fluid {
    padding-top: 1.2rem;
  }
}

@media (max-width: 769px) {
  .mob-logo {
    display: block;
  }

  header .col-lg-6 {
    order: 2;
  }

  header .col-lg-6.text-end {
    order: 1;
  }

  .wel-not {
    padding: 75px 0 0;
  }

  .prof-sett {
    position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    padding: 15px 10px;
    justify-content: space-between;
    z-index: 9;
  }

  .locker-button-text {
    left: 60px;
    top: 50%;
  }

  .locker-button-text-VA {
    left: 45px;
    top: 50%;
    font-size: 9px;
  }

  .det-prof {
    display: none;
  }

  .mob_btn_hambrgr {
    position: unset;
    margin: 0;
    width: 98px;
  }

  .mob_btn_hambrgr img {
    width: auto;
  }

  .logo-wrap.mob-logo img {
    width: 75px;
  }

  .all-head {
    display: flex;
  }

  section.sidebar_wrap .logo-wrap {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: -1;
  }

  .btns-wrap.appnt.btns-wrap-appt .btn {
    margin: 5px 10px !important;
    min-width: 40%;
  }

  .btns-wrap.appnt.btns-wrap-appt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .form-box.card-box.appnt-set-box h3,
  .form-box.card-box.appnt-set-box p {
    text-align: center;
  }

  .col-lg-cust-5 {
    width: 50%;
    padding: 0 10px;
  }

  .scanner {
    text-align: center;
    margin: 0 0 20px;
  }

  .bar-code-box .img {
    padding: 5px;
    margin-right: 10px;
  }

  .bar-code-box .img img {
    max-width: 30px;
    width: 100%;
  }

  .bar-code-box {
    margin-right: 20px;
  }

  .bar-code-text h3 {
    font-size: 16px;
  }

  .vide-bar-bottom {
    padding: 15px 20px;
  }

  .video-box img.play-btn {
    max-width: 100px;
  }

  .justify-content-center-mob {
    justify-content: center;
  }

  .seq-wrap > span {
    position: absolute;
    left: 0;
  }

  .seq-wrap {
    text-align: center;
    margin: 0 auto;
  }

  .seq-wrap .upld-img-wrap {
    margin: 20px auto;
  }

  .seq-wrap .btn.btn-prim {
    margin-left: 0;
  }

  .col.col-video-screen,
  .col.col-user-accs,
  .col.col-user-msgs {
    width: 100%;
  }

  .user-wrap {
    margin-bottom: 20px;
  }

  .vide-sec-whole-wrap {
    max-height: initial;
    height: auto;
  }

  .bottom-bar-call.seperat-row {
    position: unset;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .label-wrap {
    margin-bottom: 15px;
  }

  .form-btn-wrap .btn {
    min-width: initial;
    margin-left: 10px;
  }

  .call-detail-bar {
    padding: 20px;
    display: block;
  }

  .incoming-call-sec h3 {
    color: black;
    font-size: 17px;
    font-family: Arial;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 38.5px;
    letter-spacing: 1.2px;
    word-wrap: break-word;
    margin-bottom: 30px;
  }

  .icons-wrap-options {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 0;
  }

  .incoming-call-sec .rcvr-naming h3 {
    font-size: 20px;
  }

  .rcvr-naming p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
  }

  .dot {
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .dot1 {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .half-locker {
    margin-top: 15px;
  }

  .dot2 {
    top: 8.7rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .dot3 {
    top: 7rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .lockers img,
  .lockers-dashboard {
    margin-top: 20%;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  /* .modal-content {
    width: 60%;
    height: 30%;
  } */
}

@media (max-width: 575px) {
  .main-heading2 h1 {
    font-size: 100%;
    width: auto;
  }

  .header-row {
    flex-direction: column;
    text-align: center;
  }

  .locker-button-text {
    left: 30px;
    top: 45%;
  }

  .locker-button-text-VA {
    left: 30px;
    top: 50%;
    font-size: 9px;
  }

  .locker-button-text-small {
    left: 30px;
    top: 105px;
  }

  .locker-logo {
    text-align: center;
    width: 90%;
  }

  .form-container {
    width: 80%;
    margin-left: 0;
  }

  .col-lg-cust-5 {
    width: 75%;
    padding: 0 10px;
  }

  .scan-box-wrap {
    padding: 30px 30px;
  }

  .btn,
  .btn.btn-prim {
    margin-bottom: 10px;
  }

  .bar-code-box .img img {
    max-width: 20px;
    width: 100%;
  }

  .bar-code-box .img {
    padding: 7px;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .dot {
    height: 20px;
    width: 19px;
    font-size: 11px;
    top: 13px;
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
  }

  .half-locker {
    margin-top: 6px;
  }

  .dot1 {
    font-size: 11px;
    top: 11px;
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
  }

  .dot2 {
    height: 20px;
    width: 19px;
    font-size: 11px;
    top: 4.2rem;
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
  }

  .dot3 {
    height: 28px;
    width: 28px;
    font-size: 12px;
    top: 4rem;
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
  }

  /* .modal-content {
    width: 60%;
    height: 30%;
  } */

  .image-container img {
    width: 100%;
    margin-left: 0;
    margin-top: 50px;
  }

  .lockers img,
  .lockers-dashboard {
    margin-top: 10%;
    width: 100%;
    padding: 2.5px;
    object-fit: fill;
  }

  .col-4 span {
    display: none;
  }

  .locker-timer p {
    font-size: 10px;
    font-weight: 600;
  }

  .login-container {
    height: 100vh;
  }
}

@media (max-width: 376px) {
  .half-locker {
    margin-top: 6px;
  }

  .dot {
    transform: translateX(-50%);
  }

  .dot1 {
    left: 50%;
    transform: translateX(-50%);
  }

  .dot2 {
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .dot3 {
    top: 3rem;
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
  }

  .lockers img,
  .lockers-dashboard {
    margin-top: 10%;
    width: 100%;
    padding: 2.5px;
    object-fit: fill;
  }

  .left-column-side {
    order: 2;
  }

  .right-column-side {
    order: 1;
  }

  .col-4 span {
    display: none;
  }

  .locker-timer p {
    font-size: 10px;
    font-weight: 600;
  }

  .login-container {
    height: 100vh;
  }
}

@media (max-width: 280px) {
  .dot {
    height: 18px;
    width: 18px;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .dot1 {
    top: 0.3rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .dot2 {
    top: 3.3rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .dot3 {
    top: 7rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .lockers img,
  .lockers-dashboard {
    margin-top: 20%;
  }

  .lockers img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .col-4 span {
    display: none;
  }

  .locker-timer p {
    font-size: 10px;
    font-weight: 600;
  }
}

#logout-btn {
  width: 50%;
  line-height: 10px;
  background-color: #fff;
}

.loading-disabled {
  pointer-events: none;
  /* Disable pointer events */
  opacity: 0.6;
  /* Reduce opacity to visually indicate disabled state */
}

.ReactModalPortal {
  position: absolute !important;
  z-index: 9999999 !important;
}

.ReactModal__Content {
  outline: none;
}
