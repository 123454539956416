/* calendar.css */

/* Customize calendar header */
.rbc-toolbar {
    background: #007bff;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  
  /* Buttons inside the header */
  .rbc-btn-group button {
    background: white;
    color: #007bff;
    border: none;
    padding: 5px 10px;
    margin: 0 2px;
    border-radius: 5px;
    transition: 0.3s ease;
  }
  
  .rbc-btn-group button:hover {
    background: #0056b3;
    color: white;
  }
  
  /* Customize calendar grid */
  .rbc-month-view {
    background: #f8f9fa;
    border-radius: 5px;
    padding: 10px;
  }
  
  /* Highlight today's date */
  .rbc-today {
    background: #ffeb3b !important;
  }
  
  /* Event styles */
  .rbc-event {
    background: #28a745 !important;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }
  