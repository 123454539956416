/* calendar.css */

/* Customize calendar header */
.rbc-toolbar {
    background: #007bff;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  
  /* Buttons inside the header */
  .rbc-btn-group button {
    background: white;
    color: #007bff;
    border: none;
    padding: 5px 10px;
    margin: 0 2px;
    border-radius: 5px;
    transition: 0.3s ease;
  }
  
  .rbc-btn-group button:hover {
    background: #0056b3;
    color: white;
  }
  
  /* Customize calendar grid */
  .rbc-month-view {
    background: #f8f9fa;
    border-radius: 5px;
    padding: 10px;
  }
  
  /* Highlight today's date */
  .rbc-today {
    background: #ffeb3b !important;
  }
  
  /* Event styles */
  .rbc-event {
    background: #28a745 !important;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }
  .rbc-day-bg {
    pointer-events: auto;
  }
  
  .rbc-day-bg.disabled {
    background-color: #c0b8b8 !important; /* Gray out past dates */
    pointer-events: none !important; /* Disable clicks */
    opacity: 0.5;
  }
  .otp-input-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .otp-input {
    width: 60px;
    height: 50px;
    margin: 0 5px;
    font-size: 40px;
    text-align: center;
    color: #012267;
    border: none;
    border-bottom: 3px solid #012267;

  }
  @media (max-width: 575px) {
    .otp-input {
      width: 40px;
      height: 40px;
      font-size: 30px;
    }
    
  }
  @media (max-width: 376px) {
    .otp-input-container {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .otp-input {
      width: 30px;  
      height: 30px;
      font-size: 20px;
    }
  }
  @media (max-width: 280px) {
    .otp-input-container {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .otp-input {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
  

