body {
  background-color: #f4f8f9;
}

table {
  max-height: 70vh;
 
}

td {
  position: relative;
}

.heading {
  height: 100%;
}

.sign_sec {
  height: 100%;
}

.sign_hero_wrap img {
  max-width: 100%;
  width: 100%;
}

.cont_form_wrap {
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 0.5px #eeeeee solid;
  height: 100%;
  padding: 30px 75px;
}

.cont_form_wrap h1 {
  color: #012269;
  font-size: 22px;
  font-family: Arial;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
  padding: 38px 0 45px;
}

.ReactModalPortal {
  position: absolute !important;
  z-index: 9999999 !important;
}

@media (max-width: 1399px) {
  .cont_form_wrap {
    padding: 30px 25px;
  }

  .cont_form_wrap h1 {
    padding: 30px 0;
  }
}

@media (max-width: 475px) {
  .locker-side .col-lg-9 {
    order: 2;
  }

  .call-side .col-lg-3 {
    order: 1;
  }
}

body {
  background-color: #f4f8f9;
}

table {
  overflow: scroll;
  max-height: 70vh;

}

.lockersTable {

  width: 100%;


}

.lockersImg {
  object-fit: contain;
  max-width: 10rem;
}

.lockerContainer {
  width: fit-content;
  position: relative;
}

.lockerNum {
  position: absolute;
  top: 2rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  font-weight: bold;
}