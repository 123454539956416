/* pagination.css */
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  justify-content: center;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  color: #012269;
  cursor: pointer;
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination a:hover {
  background-color: white;
}

.pagination .active a {
  background-color: #012269;
  color: white;
  border-color: #012269;
}

.pagination .break-me {
  cursor: default;
  padding: 8px 12px;
  border: 1px solid transparent;
}

.pagination .previous,
.pagination .next {
  font-weight: bold;
}

.pagination .disabled a {
  color: #6c757d;
  cursor: not-allowed;
}

.btn-stringee {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap; /* Prevent text wrapping */
  background: rgba(1, 34, 105, 1);
  color: #fff;
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  font-size: 1.2rem; /* Adjust initial font size */
}
.btn.btn-stringee:hover,
.btn.btn-stringee:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}

@media (max-width: 768px) {
  .btn-stringee {
    width: 50%; /* Adjust width for smaller screens */
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 576px) {
  .btn-stringee {
    width: 70%; /* Further adjust width for very small screens */
    font-size: 0.9rem; /* Further adjust font size */
  }
}

@media (max-width: 767px) {
  .col-md-6,
  .col-lg-6 {
    width: 100%;
  }
}
